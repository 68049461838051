/*
 * @Description:
 * @Author: 谢永红
 * @Date: 2020-06-03 14:43:06
 * @LastEditors:
 * @LastEditTime: 2021-01-25 10:09:57
 */
import React, { useEffect } from "react";
import { Layout } from "antd";
import { Header } from "components";
import { observer } from "mobx-react";
import { AliveScope } from "react-activation";
import Routers from "./router";
import { userStore, menuStore, platformParamsStore } from "store";
import { UserInfoType } from "store/user";
import { getLocalJson } from "util/comm";
import "./App.less";
import { bucAPIResponse } from "server/buc/index.globals";
import { toJS } from "mobx";

function App() {
  useEffect(() => {
    queryPlatformParams();
    if (process.env.NODE_ENV !== "development") {
      // 获取当前域名
      const locationName: string = (window as any).location.hostname;
      if (!isIPv4Address(locationName)) {
        // 修复域名跳转
        // 获取一级域名
        const domainNameArr: Array<string> = locationName.split(".");
        domainNameArr.shift();
        document.domain = domainNameArr.join(".");
      }
    }
    if (!menuStore.menu.length) {
      menuStore.updateMenu(
        getLocalJson<Array<bucAPIResponse.MenuList>>("tx_menu")
      );
      userStore.updateUserInfo(getLocalJson<UserInfoType>("tx_userInfo")!);
    }
  }, []);

  const queryPlatformParams = () => {
    platformParamsStore.queryParams();
  };
  // 判断数字域名
  function isIPv4Address(str: string) {
    // 正则表达式匹配 IPv4 地址
    const ipv4Regex = /^(\d{1,3}\.){3}\d{1,3}$/;

    // 检查是否匹配正则表达式
    if (!ipv4Regex.test(str)) {
      return false;
    }

    // 分割字符串并检查每个部分是否在 0 到 255 之间
    const parts = str.split(".");
    for (let part of parts) {
      const num = Number(part);
      if (num < 0 || num > 255 || part !== num.toString()) {
        return false;
      }
    }

    return true;
  }
  return (
    <div className="App">
      <Layout style={{ height: "100vh" }}>
        {/* 头部 */}
        <Header></Header>
        <Layout className="app-content">
          {/* 渲染一级路由 */}
          <AliveScope>
            {menuStore.menu.length && Routers(menuStore.menu, true)}
          </AliveScope>
        </Layout>
      </Layout>
    </div>
  );
}

export default observer(App);
