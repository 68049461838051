/*
 * @Description: 
 * @Author: 谢永红
 * @Date: 2020-10-30 11:29:04
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-01-12 14:51:29
 */
import TileGrid from "ol/tilegrid/TileGrid";
import { addProjection, addCoordinateTransforms, transformExtent } from "ol/proj";
import Projection from 'ol/proj/Projection'
import projzh from "projzh";
import Tile from "ol/layer/Tile";
import XYZ from "ol/source/XYZ";
import { applyTransform } from "ol/extent";

const mc2gcj02mc: any = function (input: any, opt_output: any, opt_dimension: any) {
  var output = projzh.projection.sphericalMercator.inverse(input, opt_output, opt_dimension);
  output = projzh.datum.gcj02.fromWGS84(output, output, opt_dimension);
  return projzh.projection.sphericalMercator.forward(output, output, opt_dimension);
}

const gcj02mc2mc: any = function (input: any, opt_output: any, opt_dimension: any) {
  var output = projzh.projection.sphericalMercator.inverse(input, opt_output, opt_dimension);
  output = projzh.datum.gcj02.toWGS84(output, output, opt_dimension);
  return projzh.projection.sphericalMercator.forward(output, output, opt_dimension);
}

const gcj02mc2ll: any = function (input: any, opt_output: any, opt_dimension: any) {
  var output = projzh.projection.sphericalMercator.inverse(input, opt_output, opt_dimension);
  return projzh.datum.gcj02.toWGS84(output, output, opt_dimension);
}

const ll2gcj02mc: any = function (input: any, opt_output: any, opt_dimension: any) {
  var output = projzh.datum.gcj02.fromWGS84(input, opt_output, opt_dimension);
  return projzh.projection.sphericalMercator.forward(output, output, opt_dimension);
}

export const AmapMapType: string = 'amap'

var gcj02mc = new Projection({
  code: AmapMapType,
  extent: applyTransform([-180, -90, 180, 90], ll2gcj02mc),
  units: 'm'
});

addProjection(gcj02mc);
addCoordinateTransforms('EPSG:4326', gcj02mc, ll2gcj02mc, gcj02mc2ll);
addCoordinateTransforms('EPSG:3857', gcj02mc, mc2gcj02mc, gcj02mc2mc);

let resolutions = []
for (let i = 0; i < 19; i++) {
  resolutions[i] = Math.pow(2, 18 - i) * 0.5971642833948135;
}

export const GoogleXYZ = new XYZ({
  url: 'http://www.google.cn/maps/vt?lyrs=m@189&gl=cn&x={x}&y={y}&z={z}&hl=en-US&size=1&scl=1',
  // TODO EPSG:3857
  projection: gcj02mc,
  maxZoom: 18,
  tileGrid: new TileGrid({
    origin: [-20037508.342789244, 20037508.34278071],
    minZoom: 3,
    tileSize: [256, 256],
    resolutions: resolutions
  })
})

export default new Tile({
  source: GoogleXYZ
})

