/*
 * @Description:
 * @Author: shenkaiyao
 * @Date: 2020-09-03 16:41:06
 * @LastEditors: your Name
 * @LastEditTime: 2024-10-29 12:31:55
 */
import api from './index';
import axios from '../axios';
import { VehicleId, webAPIRequest, PageProps, webAPIResponse, CommandId, CommandIds } from './index.globals';
import proxy from '../proxy';

const MODULE = '/sendCommand';

const MODULECOM = proxy + '/ztvideo';

export interface CommandStatus {
  /** 指令状态 */
  status: string;
  /** 指令状态中文描述 */
  statusName: string;
}

interface terminalLogPayload extends PageProps {
  /** 开始时间 */
  startTime?: string;
  /** 结束时间 */
  endTime?: string;
  /** 终端卡号 */
  simNo?: string;
  /** 车辆id */
  vehicleId?: string;
}

interface CommandStatusForPicture extends CommandStatus {
  fileName: string;
}

export interface CommandStatusList extends CommandStatus, VehicleId {
  /** 指令id */
  cmdId: string;
  /** 下发时间 */
  createDate: string;
  /** 所属车组 */
  depName: string;
  /** 车牌号 */
  plateNo: string;
  /** 终端手机号/sim卡号 */
  simNo: string;
  /** 执行时间 */
  updateDate: string;
}

export interface TerminalInfo {
  /** 通信模块属性 */
  commProperty?: string;
  /** GNSS模块属性 */
  gnssProperty?: string;
  /** 硬件版本号 */
  hardwareVersion?: string;
  /** 制造商ID */
  manufactureId?: string;
  /** ICCID */
  simICCD?: string;
  /** 固件版本号 */
  softwareVersion?: string;
  /** 终端ID */
  terminalId?: string;
  /** 终端型号 */
  terminalModelNo?: string;
  /** 终端类型 */
  terminalType?: string;
}

/** 右键-下发视频下载指令 */
export interface VideoDownloadType extends VehicleId {
  /** 报警标志 */
  alarmStatus: string;
  /** 通道号 */
  channel: number;
  /** 结束时间 */
  endDate: string;
  /** 开始时间 */
  startDate: string;
  /** 存储器类型 */
  storeType: number;
  /** 码流类型 */
  streamType: number;
  /** 任务执行条件 */
  taskCondition: string;
  /** 指令类型，前端传入的值 */
  type?: string;
  /** 音视频类型 */
  videoType: number;
  fileSize: number;
}

/** 下发控制文件上传指令 */
export interface FileUpload extends VehicleId {
  /** 对应文件上传消息的Id */
  commandId: string;
  /** 控制指令关键字 0暂停，1继续 2取消 */
  controlCmd: 0 | 1 | 2;
}

export interface LocationTrackingType extends VehicleId {
  /** 位置上报时间间隔(s) */
  interval: number;
  /** 跟踪有效期(s) */
  timeSpan: number;
}

/** 下发文本指令 */
export const sendText = (payload: webAPIRequest.SendText) => axios.post<string>(`${api}${MODULE}/send/text/command`, payload);

/**下发拍照指令 */
export const sendBubbleNow = (payload: webAPIRequest.SendTakePicture) => axios.post<string>(`${api}${MODULE}/send/bubble/now`, payload);

/** 下发拍照指令 */
export const sendTakePicture = (payload: webAPIRequest.SendTakePicture) => axios.post<string>(`${api}${MODULE}/send/take/picture`, payload);

/** 下发定时拍照指令 */
export const sendTakePictureTimer = (payload: webAPIRequest.SendPictureTimer) => axios.post(`${api}${MODULE}/send/bubble/timer`, payload);

/** 气泡-发送获取定时拍照参数指令 */
export const getTakePictureTimer = (payload: VehicleId) => axios.post(`${api}${MODULE}/send/query/bubble/timer`, payload);

/** 下发监听指令 */
export const sendListen = (payload: webAPIRequest.SendListen) => axios.post<string>(`${api}${MODULE}/send/listen`, payload);

/** 下发获取终端参数的命令 */
export const getTerminalParameter = (payload: VehicleId) => axios.post<string>(`${api}${MODULE}/getTerminalParameter`, payload);

/** 右键-根据指令id获取终端信息 */
export const queryTerminalInfo = (payload: CommandId) => axios.post<TerminalInfo>(`${api}${MODULE}/query/terminal/info`, payload);

/** 获取命令状态 */
export const getCommandStatus = (payload: CommandId) => axios.post<CommandStatus>(`${api}${MODULE}/getCommandStatus`, payload);

/** 获取命令状态-批量 */
export const getCommandStatusList = (payload: CommandIds) => axios.post<CommandStatusList[]>(`${api}${MODULE}/findCommandStatusList`, payload);

/** 获取命令状态-针对拍照指令 */
export const getCommandStatusForPicture = (payload: CommandId) => axios.post<CommandStatusForPicture>(`${api}${MODULE}/getCommandStatusForPicture`, payload);

/** 获取终端日志分页 */
export const getTerminalLog = (payload: terminalLogPayload) => axios.post(`${api}${MODULE}/findByPage`, payload);

/** 气泡-点名 */
export const rollCall = (payload: VehicleId) => axios.post(`${api}${MODULE}/rollCall`, payload);

/** 气泡-获取定时拍照参数 */
export const getBubbleTimer = (payload: CommandId) => axios.post<webAPIResponse.getBubbleTimerParams>(`${api}${MODULE}/query/bubble/timer`, payload);

/** 右键-下发视频下载指令 */
export const videoDownload = (payload: VideoDownloadType) => axios.post<string>(`${api}${MODULE}/send/video/download`, payload);

/** 下发控制文件上传指令 */
export const fileUpload = (payload: FileUpload) => axios.post(`${api}${MODULE}/send/control/upload`, payload);

/** 右键-下发位置追踪指令 */
export const sendLocationTracking = (payload: LocationTrackingType) => axios.post<string>(`${api}${MODULE}/send/location/tracking`, payload);

/** 右键-下发语音对讲指令 */
export const talkbackSendCmd = (payload: any) => axios.post<string>(`${MODULECOM}/talkback/sendCmd`, payload);

/** 右键-对讲心跳接口 */
export const talkbackHeartbeat = (payload: any) => axios.post<string>(`${MODULECOM}/talkback/heartbeat`, payload);

/** 右键-关闭对讲接口 */
export const talkbackClose = (payload: any) => axios.post<string>(`${MODULECOM}/talkback/close`, payload);
